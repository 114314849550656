import { gql } from 'graphql-tag'
import {
  fragmentModuleMedia,
  fragmentModuleNavigationLink,
  fragmentModuleUsp,
  fragmentModuleVideo
} from '../../../core/data-layer/modules/fragments'

import { fragmentBlockCompanyFormula, fragmentBlockMediaCardGroup, fragmentBlockQuote } from '../../../core/data-layer/blocks/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentModuleVideo}
  ${fragmentModuleMedia}
  ${fragmentBlockMediaCardGroup}
  ${fragmentBlockQuote}
  ${fragmentModuleUsp}
  ${fragmentBlockCompanyFormula}
  query GetPageCareers($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        headingTitle
        headingMedia {
          ...ModuleVideoFragment
        }
        introTitle
        introDescription {
          json
        }
        introLink {
          ...ModuleNavigationLinkFragment
        }
        companyFormula {
          ...BlockCompanyFormulaFragment
        }
        quote {
          ...BlockQuoteFragment
        }
        careerTypes {
          ...BlockMediaCardGroupFragment
        }
        locationsMedia {
          ...ModuleMediaFragment
        }
        locationsTitle
        benefitsTitle
        benefitsCollection {
          items {
            ...ModuleUspFragment
          }
        }
      }
    }
  }
`
